import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import OurServicesIcon from "../assets/images/Our Services.svg";
import WarrantyIcon from "../assets/images/Warranty.svg";
import ValuePlusIcon from "../assets/images/VALUE PLUS.svg";

const AccordianSection = () => {
  const [expanded, setExpanded] = useState("");

  const handleExpansion = (id) => {
    const value = expanded === id ? "" : id;
    setExpanded(value);
  };

  const accordians = [
    {
      headerText: "Our services",
      svg: OurServicesIcon,
      id: "panel1-header",
      children: [
        "Modular kitchens",
        "Modular wardrobes",
        "Living area",
        "Dining area",
        "Lighting",
        "Electrical work",
        "False ceiling",
        "Wall design & painting",
      ],
    },
    {
      headerText: "Warranty",
      svg: WarrantyIcon,
      id: "panel2-header",
      children: [
        "Upto 1 year on-site free service",
        "Flat 10 years warranty (Warranty on plywoods and laminates)",
      ],
    },
    {
      headerText: "Value plus",
      svg: ValuePlusIcon,
      id: "panel3-header",
      children: ["No hidden charges", "No air bubbles", "Quality materials"],
    },
  ];

  return (
    <div style={{ width: "95%", margin: "20px auto" }}>
      {accordians.map((data, i) => (
        <Accordion
          expanded={expanded === data.id}
          onChange={() => handleExpansion(data.id)}
          slots={{ transition: Fade }}
          slotProps={{ transition: { timeout: 400 } }}
          sx={{
            "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
            "& .MuiAccordionDetails-root": {
              display: expanded ? "block" : "none",
            },
          }}
          key={i}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={data.id}
            id={data.id}
          >
            <img src={data.svg} />
            <Typography>{data.headerText}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              {data.children.map((text, j) => (
                <li key={j}>{text}</li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordianSection;
