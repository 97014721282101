import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    uid: "",
    name: "",
    emailId: "",
    profilePicture: "",
    isAdmin: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { updateUserData } = userSlice.actions;

export const selectUserData = (state) => state.user.userData;

export default userSlice.reducer;
