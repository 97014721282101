import React from "react";
import { useDispatch } from "react-redux";
import { updateShowForm } from "../redux/mainSlice";

const MainContent = () => {
  const dispatch = useDispatch();

  return (
    <div className="main-wrapper">
      <img
        alt="website under development gif"
        src={
          "https://firebasestorage.googleapis.com/v0/b/hongirana-interiors.appspot.com/o/main%20images%2Fmain-pic.jpg?alt=media&token=f00efbef-07e2-41f1-9755-61aefc196ae5"
        }
      />
      <button onClick={() => dispatch(updateShowForm(true))}>
        Book a Free Consultation
      </button>
      <div className="banner--fadeBottom" />
    </div>
  );
};

export default MainContent;
