import React, { useState, useEffect } from "react";
import "./Gallery.css";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Gallery = () => {
  const [contentType, setContentType] = useState("kitchen");
  const [imageURLs, setImageURLs] = useState([]);
  const types = [
    { label: "Kitchen", key: "kitchen" },
    { label: "Bed Room", key: "bedRoom" },
    { label: "Living Area", key: "livingArea" },
    { label: "Dining Area", key: "diningArea" },
  ];
  const [selectedImage, setSelectedImage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    setOpen(true);
    setSelectedImage(url || "");
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  useEffect(() => {
    const fetchImages = async () => {
      const imagesList = [];
      const imagesSubCollection = collection(
        db,
        `all-images/${contentType}/images`
      );
      const imagesSnapshot = await getDocs(imagesSubCollection);
      imagesSnapshot.forEach((imageDoc) => {
        imagesList.push(imageDoc.data());
      });

      const urlsList = [];
      imagesList.map((image) => {
        urlsList.push(...image.urls);
      });
      setImageURLs(urlsList);
    };

    fetchImages();
  }, [contentType]);

  return (
    <>
      <div className="gallery-container">
        <div className="gallery-header">
          {types.map((type) => (
            <span
              className={type.key === contentType && "active-type"}
              key={type.key}
              onClick={() => setContentType(type.key)}
            >
              {type.label}
            </span>
          ))}
        </div>
        <div className="image-container">
          {imageURLs.length > 0 ? (
            imageURLs.map((imageLink, i) => (
              <img
                key={i}
                src={imageLink}
                alt="image"
                loading="lazy"
                onClick={() => handleClickOpen(imageLink)}
              />
            ))
          ) : (
            <div className="no-photos">
              No photos available at this moment for this category, please load
              other categories
            </div>
          )}
        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <img loading="lazy" src={selectedImage} className="zoomed-image" />
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default Gallery;
