import React from "react";

const AboutUS = () => {
  return (
    <div className="about-us-wrapper">
      <h1>
        About Hongirana Interiors - We offer superior interior design services:
      </h1>
      <br />
      <article>
        &nbsp;&nbsp;&nbsp;&nbsp; Hongirana Interiors is one of the best interior
        design firms in Bengaluru, providing affordable creative interior design
        services with our expert designers. We have successfully completed
        numerous residential projects. Apartments, villas, and duplexes are
        examples of interior design projects. We specialise in European and
        Contemporary interior design, including modular kitchens, living rooms,
        bedrooms, and dining rooms. We place a premium on excellence, quality,
        honesty, and transparency.
      </article>
      <br />
      <article>
        &nbsp;&nbsp;&nbsp;&nbsp;Our mission statement is Transforming Spaces,
        Transforming Lives. We want to change not only the homeowners for whom
        we work, but also the home builders who work for us.
      </article>
    </div>
  );
};

export default AboutUS;
