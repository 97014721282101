import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSideBar: false,
  showForm: false,
  projectsDetails: [],
  kitchenImagesData: [],
  bedRoomImagesData: [],
  livingAreaImagesData: [],
  diningAreaImagesData: [],
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateShowSideBar: (state, action) => {
      state.showSideBar = action.payload;
    },
    updateShowForm: (state, action) => {
      state.showForm = action.payload;
    },
    updateProjectsDetails: (state, action) => {
      state.projectsDetails = action.payload;
    },
    pushProjectDetails: (state, action) => {
      state.projectsDetails.push(action.payload);
    },
    updateKitchenImagesData: (state, action) => {
      state.kitchenImagesData = action.payload;
    },
    updateBedRoomImagesData: (state, action) => {
      state.bedRoomImagesData = action.payload;
    },
    updateLivingAreaImagesData: (state, action) => {
      state.livingAreaImagesData = action.payload;
    },
    updateDiningAreaImagesData: (state, action) => {
      state.diningAreaImagesData = action.payload;
    },
    pushImagesBasedOnKey: (state, action) => {
      state[action.payload.key].push(action.payload.value);
    },
  },
});

export const {
  updateShowSideBar,
  updateShowForm,
  updateProjectsDetails,
  updateKitchenImagesData,
  updateBedRoomImagesData,
  updateLivingAreaImagesData,
  updateDiningAreaImagesData,
  pushProjectDetails,
  pushImagesBasedOnKey,
} = mainSlice.actions;

export const selectShowSideBar = (state) => state.main.showSideBar;
export const selectShowForm = (state) => state.main.showForm;
export const selectProjectsDetails = (state) => state.main.projectsDetails;
export const selectKitchenImagesData = (state) => state.main.kitchenImagesData;
export const selectBedRoomImagesData = (state) => state.main.bedRoomImagesData;
export const selectLivingAreaImagesData = (state) =>
  state.main.livingAreaImagesData;
export const selectDiningAreaImagesData = (state) =>
  state.main.diningAreaImagesData;

export default mainSlice.reducer;
