import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { selectShowSideBar, updateShowSideBar } from "../redux/mainSlice";
import Logo from "../assets/images/Logo.png";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSideBar = useSelector(selectShowSideBar);
  const [showBurgerIcon, setShowBurgerIcon] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1024) setShowBurgerIcon(true);
    else setShowBurgerIcon(false);
  }, []);

  return (
    <nav className="header-wrapper">
      <img
        className="header-logo"
        alt="Hongirana Interiors logo"
        tabIndex={0}
        src={Logo}
        onClick={() => navigate("/")}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Space")
            navigate("/");
        }}
      />
      <div className="nav-wrapper">
        {showBurgerIcon ? (
          <>
            {!showSideBar ? (
              <span
                tabIndex={0}
                onClick={() => dispatch(updateShowSideBar(true))}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " " || e.key === "Space")
                    dispatch(updateShowSideBar(true));
                }}
              >
                <MenuRoundedIcon />
              </span>
            ) : (
              <span
                tabIndex={0}
                onClick={() => dispatch(updateShowSideBar(false))}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " " || e.key === "Space")
                    dispatch(updateShowSideBar(false));
                }}
              >
                <CloseRoundedIcon />
              </span>
            )}
          </>
        ) : (
          <>
            <span
              tabIndex={0}
              onClick={() => navigate("/")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Space")
                  navigate("/");
              }}
            >
              Home
            </span>
            <span
              tabIndex={0}
              onClick={() => navigate("/projects")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Space")
                  navigate("/projects");
              }}
            >
              Projects
            </span>
            <span
              tabIndex={0}
              onClick={() => navigate("/gallery")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Space")
                  navigate("/gallery");
              }}
            >
              Gallery
            </span>
            <span
              tabIndex={0}
              onClick={() => navigate("/contact-us")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Space")
                  navigate("/contact-us");
              }}
            >
              Contact Us
            </span>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
