import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectShowForm, selectShowSideBar } from "./redux/mainSlice";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import AboutUS from "./pages/AboutUS";
import Projects from "./pages/Projects";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import HeaderMenu from "./components/HeaderMenu";
import PageNotFound from "./pages/PageNotFound";
import ContactAI from "./components/ContactAI";
import ContactForm from "./components/ContactForm";
import Services from "./pages/Services";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute";
import useFetch from "./hooks/useFetch";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/firebase";
import { WhatsApp } from "@mui/icons-material";

const App = () => {
  const showSideBar = useSelector(selectShowSideBar);
  const showForm = useSelector(selectShowForm);

  const { fetchUserData } = useFetch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        fetchUserData(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Router>
        <Header />
        {showSideBar && <HeaderMenu />}
        {showForm && <ContactForm />}
        <ContactAI />
        <a
          href="//api.whatsapp.com/send?phone=918660126798&text=Hi, I would like to know more about your company and works you have done"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-logo"
        >
          <WhatsApp />
        </a>
        <div className="pages-wrapper">
          <Routes>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/about-us" element={<AboutUS />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/ssar"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
