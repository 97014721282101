import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateShowSideBar } from "../redux/mainSlice";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="burger-dropdown">
      <span
        tabIndex={0}
        onClick={() => {
          dispatch(updateShowSideBar(false));
          navigate("/");
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Space") {
            dispatch(updateShowSideBar(false));
            navigate("/");
          }
        }}
      >
        Home
      </span>
      <span
        tabIndex={0}
        onClick={() => {
          dispatch(updateShowSideBar(false));
          navigate("/projects");
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Space") {
            dispatch(updateShowSideBar(false));
            navigate("/projects");
          }
        }}
      >
        Projects
      </span>
      <span
        tabIndex={0}
        onClick={() => {
          dispatch(updateShowSideBar(false));
          navigate("/gallery");
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Space") {
            dispatch(updateShowSideBar(false));
            navigate("/gallery");
          }
        }}
      >
        Gallery
      </span>
      <span
        tabIndex={0}
        onClick={() => {
          dispatch(updateShowSideBar(false));
          navigate("/contact-us");
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Space") {
            dispatch(updateShowSideBar(false));
            navigate("/contact-us");
          }
        }}
      >
        Contact Us
      </span>
    </div>
  );
};

export default HeaderMenu;
