import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import { updateShowForm } from "../redux/mainSlice";

import "./ContactAI.css";

const ContactAI = () => {
  const dispatch = useDispatch();
  const [openOptions, setOpenOptions] = useState(false);

  return (
    <>
      <div
        className="chatbot"
        tabIndex={0}
        onClick={() => setOpenOptions((prevState) => !prevState)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " " || e.key === "Space")
            setOpenOptions((prevState) => !prevState);
        }}
      >
        <SmartToyOutlinedIcon />
      </div>
      {openOptions && (
        <div className="options-wrapper">
          <a
            href="tel:+918660126798"
            rel="noopener noreferrer"
            onClick={() => setOpenOptions((prevState) => !prevState)}
          >
            <CallRoundedIcon />
          </a>
          <a
            href="mailto:info@hongiranainteriors.in"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setOpenOptions((prevState) => !prevState)}
          >
            <EmailRoundedIcon />
          </a>
          <FormatListBulletedRoundedIcon
            onClick={() => {
              setOpenOptions((prevState) => !prevState);
              dispatch(updateShowForm(true));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " " || e.key === "Space") {
                setOpenOptions((prevState) => !prevState);
                dispatch(updateShowForm(true));
              }
            }}
            tabIndex={0}
          />
        </div>
      )}
    </>
  );
};

export default ContactAI;
