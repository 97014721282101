import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { storage } from "../config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../hooks/useFetch";

const Admin = () => {
  const { uploadProjectDetails } = useFetch();

  const [projectNameAndDescription, setProjectNameAndDescription] = useState({
    projectName: "",
    projectDescription: "",
  });

  const [kitchenImages, setKitchenImages] = useState([]);
  const [bedRoomImages, setBedRoomImages] = useState([]);
  const [livingAreaImages, setLivingAreaImages] = useState([]);
  const [diningAreaImages, setDiningAreaImages] = useState([]);

  const [progress, setProgress] = useState(0);

  const uploadImages = async (e, category) => {
    e.preventDefault();
    const filesArray = Array.from(e.target.files);
    const promises = filesArray.map((image) => {
      const storageRef = ref(storage, `project-images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      });
      return uploadTask;
    });
    const snapshots = await Promise.all(promises);
    const urls = await Promise.all(
      snapshots.map((snapshot) => getDownloadURL(snapshot.ref))
    );
    switch (category) {
      case "kitchen":
        setKitchenImages([...kitchenImages, ...urls]);
        break;
      case "bedRoom":
        setBedRoomImages([...bedRoomImages, ...urls]);
        break;
      case "livingArea":
        setLivingAreaImages([...livingAreaImages, ...urls]);
        break;
      case "diningArea":
        setDiningAreaImages([...diningAreaImages, ...urls]);
        break;
      default:
        break;
    }
    setProgress(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const projectId = uuidv4();

    uploadProjectDetails({
      projectId,
      projectName: projectNameAndDescription.projectName,
      projectDescription: projectNameAndDescription.projectDescription,
      kitchenImages,
      bedRoomImages,
      livingAreaImages,
      diningAreaImages,
    });

    setProjectNameAndDescription({
      projectName: "",
      projectDescription: "",
    });
    setKitchenImages([]);
    setBedRoomImages([]);
    setLivingAreaImages([]);
    setDiningAreaImages([]);
  };

  return (
    <div className="about-us-wrapper">
      <h1 style={{ width: "100%" }}>Add Project Data</h1>
      <div className="form-wrapper">
        <TextField
          label="Project Name"
          variant="standard"
          name="projectName"
          value={projectNameAndDescription.projectName}
          onChange={(e) =>
            setProjectNameAndDescription({
              ...projectNameAndDescription,
              [e.target.name]: e.target.value,
            })
          }
        />
        <TextField
          label="Project Description"
          variant="standard"
          name="projectDescription"
          value={projectNameAndDescription.projectDescription}
          onChange={(e) =>
            setProjectNameAndDescription({
              ...projectNameAndDescription,
              [e.target.name]: e.target.value,
            })
          }
        />
        <label>Kitchen Images</label>
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file1"
            multiple
            type="file"
            onChange={(e) => uploadImages(e, "kitchen")}
          />
          <label className="upload-button-label1" htmlFor="raised-button-file1">
            <Button
              variant="raised"
              component="span"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Upload Kitchen Images
            </Button>
          </label>
        </>
        <label>Bedroom Images</label>
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file2"
            multiple
            type="file"
            onChange={(e) => uploadImages(e, "bedRoom")}
          />
          <label className="upload-button-label2" htmlFor="raised-button-file2">
            <Button
              variant="raised"
              component="span"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Upload Bedroom Images
            </Button>
          </label>
        </>
        <label>Living Area Images</label>
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file3"
            multiple
            type="file"
            onChange={(e) => uploadImages(e, "livingArea")}
          />
          <label className="upload-button-label3" htmlFor="raised-button-file3">
            <Button
              variant="raised"
              component="span"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Upload Living Area Images
            </Button>
          </label>
        </>
        <label>Dining Area Images</label>
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file4"
            multiple
            type="file"
            onChange={(e) => uploadImages(e, "diningArea")}
          />
          <label className="upload-button-label4" htmlFor="raised-button-file4">
            <Button
              variant="raised"
              component="span"
              style={{
                border: "1px solid rgba(190, 182, 182, 0.922)",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Upload Dining Area Images
            </Button>
          </label>
        </>
        {progress > 0 && progress < 100 && (
          <progress value={progress} max="100" />
        )}

        <br />
        <Button
          variant="contained"
          component="span"
          style={{
            border: "1px solid rgba(190, 182, 182, 0.922)",
            marginLeft: "10px",
            marginTop: "10px",
          }}
          disabled={progress !== 0}
          onClick={handleSubmit}
        >
          Upload Data
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Admin);
