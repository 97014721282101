import React from "react";

const ContactUs = () => {
  return (
    <div className="contact-us-wrapper">
      <section className="heightened-section">
        <h2>Address:</h2>
        <p>
          #1/1, 4th B cross,
          <br />
          S.V.G. Nagar, Moodalapalya,
          <br />
          Begaluru - 560072
        </p>
        <br />
        <br />
        <h2>E-mail:</h2>
        <a
          href="mailto:info@hongiranainteriors.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@hongiranainteriors.in
        </a>
        <br />
        <br />
        <h2>Mobile:</h2>
        <a href="tel:+918660126798" rel="noopener noreferrer">
          +91-8660126798
        </a>
      </section>
      <section className="heightened-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d971.9925102149484!2d77.524897!3d12.973768!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU4JzI1LjUiTiA3N8KwMzEnMjkuMyJF!5e0!3m2!1sen!2sin!4v1676808744378!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Location"
        ></iframe>
      </section>
    </div>
  );
};

export default ContactUs;
