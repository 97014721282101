import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <img
        alt="404 error animation"
        src="https://firebasestorage.googleapis.com/v0/b/hongirana-interiors.appspot.com/o/animations%2F42479-page-not-found-404.gif?alt=media&token=fe5ba8ce-3d12-419e-be59-b366d7dd7bc2"
        style={{ height: "50%", width: "50%" }}
      />
      <div
        style={{
          marginBottom: "100px",
          marginTop: "20px",
          textAlign: "center",
          padding: "0 20px",
        }}
      >
        <h3>
          404! This page could not be found.{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            Redirect to Home Page
          </span>
        </h3>
      </div>
    </div>
  );
};

export default PageNotFound;
