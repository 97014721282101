import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  startAfter,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData, updateUserData } from "../redux/userSlice";
import {
  pushImagesBasedOnKey,
  pushProjectDetails,
  updateProjectsDetails,
} from "../redux/mainSlice";

const useFetch = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);

  const fetchUserData = async (userId) => {
    const docRef = doc(db, "user-profiles", userId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    dispatch(
      updateUserData({
        uid: data.uid,
        name: data.name,
        emailId: data.emailId,
        profilePicture: data.profilePicture,
        isAdmin: data.isAdmin,
      })
    );
  };

  const uploadProjectDetails = async ({
    projectId,
    projectName,
    projectDescription,
    kitchenImages,
    bedRoomImages,
    livingAreaImages,
    diningAreaImages,
  }) => {
    if (!user.isAdmin) return;
    const projectDocRef = doc(db, "project-details", projectId);
    const projectData = {
      id: projectId,
      projectName,
      projectDescription,
      categories: {
        kitchen: kitchenImages.length > 0 ? [...kitchenImages] : [],
        bedRoom: bedRoomImages.length > 0 ? [...bedRoomImages] : [],
        livingArea: livingAreaImages.length > 0 ? [...livingAreaImages] : [],
        diningArea: diningAreaImages.length > 0 ? [...diningAreaImages] : [],
      },
    };
    await setDoc(projectDocRef, projectData);
    dispatch(pushProjectDetails(projectData));

    if (kitchenImages.length > 0) {
      await addDoc(collection(db, `all-images/kitchen/images`), {
        urls: [...kitchenImages],
        createdAt: new Date(),
      });
      dispatch(
        pushImagesBasedOnKey({
          key: "kitchenImagesData",
          value: [...kitchenImages],
        })
      );
    }
    if (bedRoomImages.length > 0) {
      await addDoc(collection(db, `all-images/bedRoom/images`), {
        urls: [...bedRoomImages],
        createdAt: new Date(),
      });
      dispatch(
        pushImagesBasedOnKey({
          key: "bedRoomImagesData",
          value: [...bedRoomImages],
        })
      );
    }
    if (livingAreaImages.length > 0) {
      await addDoc(collection(db, `all-images/livingArea/images`), {
        urls: [...livingAreaImages],
        createdAt: new Date(),
      });
      dispatch(
        pushImagesBasedOnKey({
          key: "livingAreaImagesData",
          value: [...livingAreaImages],
        })
      );
    }
    if (diningAreaImages.length > 0) {
      await addDoc(collection(db, `all-images/diningArea/images`), {
        urls: [...diningAreaImages],
        createdAt: new Date(),
      });
      dispatch(
        pushImagesBasedOnKey({
          key: "diningAreaImagesData",
          value: [...diningAreaImages],
        })
      );
    }
    alert("Data uploaded successfully");
  };

  const fetchProjects = async (lastVisible, setLastVisible, next = false) => {
    let projectDetailsQuery;
    if (next && lastVisible) {
      projectDetailsQuery = query(
        collection(db, `project-details`),
        startAfter(lastVisible),
        limit(100)
      );
    } else {
      projectDetailsQuery = query(
        collection(db, `project-details`),
        limit(100)
      );
    }
    const projectDetailsQuerySnapshot = await getDocs(projectDetailsQuery);
    dispatch(
      updateProjectsDetails(
        projectDetailsQuerySnapshot.docs.map((doc) => doc.data())
      )
    );
    setLastVisible(
      projectDetailsQuerySnapshot.docs[
        projectDetailsQuerySnapshot.docs.length - 1
      ]
    );
  };

  return { fetchUserData, uploadProjectDetails, fetchProjects };
};

export default useFetch;
