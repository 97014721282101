import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { updateShowForm } from "../redux/mainSlice";

import "./ContactForm.css";
import { addDoc, collection, db } from "../config/firebase";

const ContactForm = () => {
  const [details, setDetails] = useState({
    name: "",
    number: "",
    email: "",
    city: "",
  });
  const [errorCheck, setErrorCheck] = useState({
    name: false,
    number: false,
    city: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById("contact-form-wrapper").focus();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (details.name === "") {
      setErrorCheck({ ...errorCheck, name: true });
      alert(
        "Name field is a mandatory field, so please enter your name to continue"
      );
      return;
    } else if (details.number === "" || details.number.length < 10) {
      setErrorCheck({ ...errorCheck, number: true });
      alert("Number field is a mandatory field, enter a valid number");
      return;
    } else if (details.city === "") {
      setErrorCheck({ ...errorCheck, city: true });
      alert("City name field is a mandatory field, enter a valid city name");
      return;
    }
    try {
      const queryRef = await addDoc(collection(db, "clients"), { ...details });
      // TODO: Add client queries to the DB
    } catch (e) {
      alert("Error adding document to the database");
    }
    dispatch(updateShowForm(false));
    setDetails({
      name: "",
      number: "",
      email: "",
      city: "",
    });
    setErrorCheck({ name: false, number: false, city: false });
  };

  return (
    <div className="contact-form">
      <div
        className="contact-form-wrapper"
        id="contact-form-wrapper"
        tabIndex={0}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": {
              m: 1,
              width: "600px",
              maxWidth: "80vw",
              display: "flex",
              flexDirection: "column",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <div className="header-section">
            <h3>Get touch with our designer</h3>
            <CloseOutlinedIcon
              tabIndex={0}
              onClick={() => dispatch(updateShowForm(false))}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Space")
                  dispatch(updateShowForm(false));
              }}
            />
          </div>
          <TextField
            id="outlined-basic"
            label="Name"
            name="name"
            variant="outlined"
            value={details.name}
            onChange={(e) =>
              setDetails({ ...details, [e.target.name]: e.target.value })
            }
            placeholder="ex: John Wick"
            required
            error={errorCheck.name}
          />
          <TextField
            id="outlined-basic"
            label="Number"
            name="number"
            variant="outlined"
            value={details.number}
            type="number"
            onChange={(e) =>
              setDetails({ ...details, [e.target.name]: e.target.value })
            }
            placeholder="ex: 091-9876543210"
            required
            error={errorCheck.number}
          />
          <TextField
            id="outlined-basic"
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            value={details.email}
            onChange={(e) =>
              setDetails({ ...details, [e.target.name]: e.target.value })
            }
            placeholder="ex: john.wick@gmail.com"
          />
          <TextField
            id="outlined-basic"
            label="City Name"
            name="city"
            variant="outlined"
            value={details.city}
            onChange={(e) =>
              setDetails({ ...details, [e.target.name]: e.target.value })
            }
            placeholder="ex: Bengaluru"
            required
            error={errorCheck.city}
          />
          <button className="book-button" onClick={(e) => submitHandler(e)}>
            Book a Free Consultation
          </button>
          <p className="disclaimer-text">
            By submitting this form, you agree to allow us to contact you via
            WhatsApp.
          </p>
        </Box>
      </div>
    </div>
  );
};

export default ContactForm;
