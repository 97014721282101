import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { selectProjectsDetails } from "../redux/mainSlice";
import useFetch from "../hooks/useFetch";
import "./Projects.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Projects = () => {
  const [lastVisible, setLastVisible] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageURLs, setImageURLs] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setImageURLs([]);
  };

  const { fetchProjects } = useFetch();
  const projectsList = useSelector(selectProjectsDetails);

  useEffect(() => {
    fetchProjects(lastVisible, setLastVisible);
  }, []);

  const truncateText = (text) => {
    if (window.innerWidth < 1024) {
      if (text.length > 150) {
        text = text.substring(0, 150) + "...";
      }
    } else {
      if (text.length > 300) {
        text = text.substring(0, 300) + "...";
      }
    }
    return text;
  };

  const returnImageArray = (selectedProject) => {
    const images = [];
    if (selectedProject) {
      const arrayOfArray = Object.values(selectedProject.categories);
      if (arrayOfArray) {
        arrayOfArray.forEach((array) => images.push(...array));
      }
    }
    setImageURLs(images);
  };

  const getImageUrl = (categories) => {
    const image =
      categories.livingArea[0] ||
      categories.diningArea[0] ||
      categories.kitchen[0] ||
      categories.bedRoom[0];

    return image;
  };

  return (
    <>
      <div className="projects-wrapper">
        <div className="projects-grid">
          {projectsList.map((project) => (
            <div className="card" key={project.id}>
              <img
                className="card-image"
                src={getImageUrl(project.categories)}
                alt={"project image"}
                loading="lazy"
              />
              <p className="card-title">{project.projectName}</p>
              <p className="card-body">
                {truncateText(project.projectDescription)}
              </p>
              <p className="footer">
                <span
                  className="by-name"
                  onClick={() => {
                    returnImageArray(project);
                    handleClickOpen();
                  }}
                >
                  See more...
                </span>
              </p>
            </div>
          ))}
        </div>
      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Slide>
            {imageURLs &&
              imageURLs.map((slideImage, index) => (
                <img
                  key={index}
                  loading="lazy"
                  src={slideImage}
                  className="slider-image"
                />
              ))}
          </Slide>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default Projects;
