import React from "react";
import SVGCards from "../components/Card";
import MainContent from "../components/MainContent";
import AccordianSection from "../components/AccordianSection";

const HomePage = () => {
  return (
    <div className="home-wrapper">
      <MainContent />
      <h2>Why choose us?</h2>
      <SVGCards />
      <h2>What we offer</h2>
      <AccordianSection />
    </div>
  );
};

export default HomePage;
