import React from "react";
import Delivery from "../assets/images/Delivary in 45 days.svg";
import NoHiddenCharges from "../assets/images/No Hidden cost.svg";
import Warranty from "../assets/images/10yrs Warranty.svg";

const Cards = () => {
  const svgs = [
    { svg: Delivery, text: "Delivery in 45 days" },
    { svg: NoHiddenCharges, text: "No hidden charges" },
    { svg: Warranty, text: "Flat 10 years warranty" },
  ];
  return (
    <div className="cards-wrapper">
      {svgs.map((svgData, i) => (
        <div className="card-wrapper" key={i}>
          <img
            className="card-image"
            src={svgData.svg}
            alt={svgData.text}
            style={{
              backgroundColor: "white",
              boxShadow: "none",
            }}
          />
          <span>{svgData.text}</span>
        </div>
      ))}
    </div>
  );
};

export default Cards;
